import React, { Fragment } from "react"

import Layout from "../components/layout"
import PasswordForgetForm from "../components/PasswordForget"
import logo from "../images/logo-70px.png"


const PasswordForgetPage = () => (
  <Fragment>
    <section className="login-area">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 pb-70">
          <div className="login-content">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="login-form">
                  <div className="logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <h3>Forgot your password?</h3>
                  <PasswordForgetForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
)

export default () => (
  <Layout title="Forgot Your Password?" description="Reset the password to your HRS systems account.">
    <PasswordForgetPage />
  </Layout>
)
